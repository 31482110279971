import { container, title } from "assets/jss/material-kit-react.jsx";

const conversionPages = {
  container: {
    zIndex: "12",
    color: "#FFFFFF",
    ...container
  },
  title: {
    ...title,
    display: "inline-block",
    position: "relative",
    marginTop: "30px",
    minHeight: "32px",
    color: "#FFFFFF",
    textDecoration: "none"
  },
  content: {
    padding: "25px",
    margin: "20px",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  subtitle: {
    fontSize: "1.313rem",
    margin: "10px auto 0",
    color: "black",
    padding: "10px 0",
  },
  link: {
    backgroundColor: "#009688",
    color:"white",
    padding:"20px",
    borderRadius:"6px",
    fontWeight: "bolder",
    padding:"10px 15px",
  },
  img: {
    width: "80%",
    margin: "50px",
  },
  main: {
    background: "#FFFFFF",
    position: "relative",
    alignItems: "center",
    zIndex: "3"
  },
  mainRaised: {
    // margin: "-60px 30px 0px", 
    // borderRadius: "6px",
    boxShadow:
      "0 16px 24px 2px rgba(0, 0, 0, 0.14), 0 6px 30px 5px rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(0, 0, 0, 0.2)"
  }
}; 

export default conversionPages;