import React from "react";
import Helmet from "react-helmet";

import { Link } from 'gatsby';
import classNames from "classnames";
import withStyles from "@material-ui/core/styles/withStyles";
import Header from "components/Header/Header.jsx";
import Footer from "components/Footer/Footer.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import HeaderLinks from "components/Header/HeaderLinks.jsx";
import BlogShortParallax from "components/ShortParallax/BlogShortParallax.jsx";

import conversionPages from "assets/jss/material-kit-react/views/conversionPages.jsx";

const dashboardRoutes = [];

class thanksSubscribe extends React.Component {
  render() {
    const { classes, ...rest } = this.props;
    return (
      <div>
      <Helmet>
        <title>BookMerlin</title>
      </Helmet>
        <Header
          color="transparent"
          routes={dashboardRoutes}
          brand="BookMerlin"
          rightLinks={<HeaderLinks />}
          fixed
          changeColorOnScroll={{
            height: 50,
            color: "#009688" //changed from white
          }}
          {...rest}
        />
        <BlogShortParallax filter image={require("assets/img/dad.jpg")} >
          <div className={classes.container}>
            <h1 className={classes.title}>
              Thank you for subscribing to the blog!
            </h1>
          </div>
        </BlogShortParallax>
        <div className={classNames(classes.main, classes.mainRaised)} >
          <div className={classes.container} >
            <GridContainer >
              <GridItem className={classes.content}>
                <div>
                  <h5 className={classes.subtitle}>
                      This will be another conversion so the consumers will want to go to/purchase something else!
                  </h5>
                  <Link to="/blog" className={classes.link}>
                    Click here!
                  </Link>
                </div>
                <img
                    src={require('pages/HomePage/img/blogFeature.png')}
                    className={classes.img}
                />
              </GridItem>
            </GridContainer>
          </div>
        </div>
        <Footer />
      </div>
    );
  }
}

export default withStyles(conversionPages)(thanksSubscribe);
